module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"spirited-away1000h","short_name":"spirited-away1000h","description":"cloudflareをエッジコンピューティングという視点で紹介するサイト","start_url":"/","background_color":"#ffffff","theme_color":"#40c246","display":"standalone","icons":[{"src":"/icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"/icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"/icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"/icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"/icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"/icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
